<div class="hero-section">
  <div class="container">
    <div class="row text-center align-items-center">
      <div class="col-12 text-center">
        <h1>I'M DURI.</h1>
        <h2>DEVELOPER + DESIGNER</h2>
        <div class="d-flex justify-content-center">
          <p class="text-light-grey mx-2" style="font-size: 0.7rem;">ver 1.0 - 09/22</p>
        </div>
      </div>
    </div>
  </div>
</div>
