<div class="bg-black text-white px-5">
  <div class="container text-center">
    <br />
    <div class="p-3 p-lg-5">
      <h5 class="text-secondary header-title">CONTACT</h5>
      <h1 class="header">Wanna Get In Touch?</h1>

      <a
        class="btn btn-outline-light fs-4 mt-4"
        href="mailto:abdulr.ali@outlook.com"
      >
        Email Me 🙂
      </a>
    </div>
    <br />
    <div class="row py-4">
      <div
        class="col-12 col-lg-6 text-center text-lg-start mb-3 mb-lg-0 py-6px"
      >
        <a class="navbar-brand fw-bold px-2" href="">DURI.</a>
        <div class="mt-3">
          <button
            (click)="onClickLink('linkedin')"
            class="btn btn-outline-light me-3"
          >
            <i class="fa-brands fa-linkedin-in"></i>
          </button>
          <button
            (click)="onClickLink('github')"
            class="btn btn-outline-light me-3"
          >
            <i class="fa-brands fa-github"></i>
          </button>
          <button
            (click)="onClickLink('dribbble')"
            class="btn btn-outline-light me-3"
          >
            <i class="fa-brands fa-dribbble"></i>
          </button>
          <button
            (click)="onClickLink('behance')"
            class="btn btn-outline-light me-3"
          >
            <i class="fa-brands fa-behance"></i>
          </button>
        </div>
      </div>

      <!-- <div class="col-12 col-lg-6 text-center mb-5 mb-lg-0">
        <button
          (click)="onClickLink('linkedin')"
          class="btn btn-outline-light me-3"
        >
          <i class="fa-brands fa-linkedin-in"></i>
        </button>
        <button
          (click)="onClickLink('github')"
          class="btn btn-outline-light me-3"
        >
          <i class="fa-brands fa-github"></i>
        </button>
        <button
          (click)="onClickLink('dribbble')"
          class="btn btn-outline-light me-3"
        >
          <i class="fa-brands fa-dribbble"></i>
        </button>
        <button
          (click)="onClickLink('behance')"
          class="btn btn-outline-light me-3"
        >
          <i class="fa-brands fa-behance"></i>
        </button>
      </div> -->

      <div class="col-12 col-lg-6 text-center text-lg-end">
        <div class="row">
          <div class="col-12">
            <button
              class="btn btn-outline-light"
              (click)="onFooterNavClick('home')"
              aria-current="page"
            >
              Home
            </button>
            <button
              (click)="onFooterNavClick('work')"
              class="btn btn-outline-light"
              aria-current="page"
            >
              Work
            </button>
            <button
              class="btn btn-outline-light"
              (click)="onFooterNavClick('about')"
              aria-current="page"
            >
              About
            </button>
          </div>
          <div class="col-12">
            <button
              (click)="onFooterNavClick('experience')"
              class="btn btn-outline-light"
              aria-current="page"
            >
              Experience
            </button>
            <button
              class="btn btn-outline-light"
              (click)="onFooterNavClick('contact')"
              aria-current="page"
            >
              Contact
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
