import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-experience-node',
  templateUrl: './experience-node.component.html',
  styleUrls: ['./experience-node.component.scss'],
})
export class ExperienceNodeComponent implements OnInit {
  @Input() experienceInfo: {
    company: string;
    position: string;
    time: string;
    description?: string;
  } = {
    company: 'Life Healthcare',
    position: 'Software Engineer (Via Dariel)',
    time: 'March 2022 - today',
  };

  @Input() reverse: boolean = false;
  @Input() isMain: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
