<div class="container">
  <div class="card bg-black">
    <div class="card-body p-5">
      <div></div>
      <div class="row">
        <div class="col-lg-6 col-12 text-left">
          <h5 class="text-light header-title">ABOUT</h5>
          <h2 class="header text-white">
            I'm a Software Engineer who likes to design sometimes 👨🏽‍💻
          </h2>

          <br />

          <h5 class="text-grey">
            I began my software career as a freelance web developer and designer
            in 2020. I am currently working as a full-time software engineer at
            Dariel Software.
          </h5>
          <br />
          <h5 class="text-grey">
            I made this site to keep track of my design & development skills and
            share some of the projects I've worked on. I'll be updating this
            site every year with a new design using a new framework and
            hopefully with a few new projects 🤞.
          </h5>
          <br />
          <h5 class="text-grey">
            If you have any tech-stack suggestions, feedback or want to chat
            about code & design... feel free to reach out 👇.
          </h5>
          <br />
          <br />
          <div class="row">
            <div class="col-12 text-center text-lg-start p-0">
              <button
                (click)="onClickLink('linkedin')"
                class="btn btn-outline-light me-3"
              >
                <i class="fa-brands fa-linkedin-in"></i>
              </button>
              <a
                class="btn btn-outline-light me-3"
                href="mailto:abdulr.ali@outlook.com"
              >
                <i class="fa-solid fa-envelope"></i>
              </a>
              <button
                (click)="onClickLink('dribbble')"
                class="btn btn-outline-light me-3"
              >
                <i class="fa-brands fa-dribbble"></i>
              </button>
              <button
                (click)="onClickLink('behance')"
                class="btn btn-outline-light me-3"
              >
                <i class="fa-brands fa-behance"></i>
              </button>
              <button
                (click)="onClickLink('github')"
                class="btn btn-outline-light"
              >
                <i class="fa-brands fa-github"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 mt-4 mt-lg-0">
          <img
            class="img-fluid pic"
            src="../../../../assets/images/me-3.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</div>
