<nav
  class="navbar fixed-top navbar-expand-md bg-white"
  style="z-index: 1000000"
>
  <div class="container-fluid container">
    <a class="navbar-brand fw-bold" href=""> DURI. </a>

    <button
      class="navbar-toggler collapsed"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarScroll"
      aria-controls="navbarScroll"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <i class="fas fa-bars"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarScroll">
      <ul
        class="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll"
        style="--bs-scroll-height: 150px"
      >
        <li
          (click)="onNavItemClick('home')"
          class="ms-2 nav-item"
          [class.active]="currentSection === 'home'"
        >
          <button
            class="btn btn-outline-dark"
            aria-current="page"
            [class.active]="currentSection === 'home'"
          >
            Home
          </button>
        </li>
        <li
          (click)="onNavItemClick('work')"
          class="ms-2 nav-item"
          [class.active]="currentSection === 'work'"
        >
          <button
            class="btn btn-outline-dark"
            aria-current="page"
            [class.active]="currentSection === 'work'"
          >
            Work
          </button>
        </li>
        <li
          (click)="onNavItemClick('about')"
          class="ms-2 nav-item"
          [class.active]="currentSection === 'about'"
        >
          <button
            class="btn btn-outline-dark"
            aria-current="page"
            [class.active]="currentSection === 'about'"
          >
            About
          </button>
        </li>
        <li
          (click)="onNavItemClick('experience')"
          class="ms-2 nav-item"
          [class.active]="currentSection === 'experience'"
        >
          <button
            class="btn btn-outline-dark"
            aria-current="page"
            [class.active]="currentSection === 'experience'"
          >
            Experience
          </button>
        </li>
        <li
          (click)="onNavItemClick('contact')"
          class="ms-2 nav-item"
          [class.active]="currentSection === 'contact'"
        >
          <button
            class="btn btn-outline-dark"
            aria-current="page"
            [class.active]="currentSection === 'contact'"
          >
            Contact
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
