import { Component, Input, OnInit } from '@angular/core';
import { LinkService } from 'src/app/services/link.service';

@Component({
  selector: 'app-project-item',
  templateUrl: './project-item.component.html',
  styleUrls: ['./project-item.component.scss'],
})
export class ProjectItemComponent implements OnInit {
  @Input() projectItemInfo: {
    title: string;
    description: string;
    image: string;
    url: string;
    year: number;
  } = {
    description: 'Complete Learning Management System',
    title: 'BetterLearn',
    image: 'betterlearn.png',
    url: 'https://www.youtube.com/watch?v=6MCF-mZ9a5o',
    year: 2021
  };

  constructor(private linkService: LinkService) {}

  ngOnInit(): void {}

  onClick() {
    this.linkService.openLinkInNewTab(this.projectItemInfo.url);
  }
}
