import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  @Input() currentSection: string = 'home';

  @Output() navClick: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onNavItemClick(event: string) {
    this.navClick.emit(event);
  }
}
