import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  currentSection: string = 'home';

  @ViewChild('home') homeElement: ElementRef = new ElementRef(null);
  @ViewChild('work') workElement: ElementRef = new ElementRef(null);
  @ViewChild('about') aboutElement: ElementRef = new ElementRef(null);
  @ViewChild('experience') experienceElement: ElementRef = new ElementRef(null);
  @ViewChild('contact') contactElement: ElementRef = new ElementRef(null);

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    let homeEl = this.homeElement.nativeElement;
    let workEl = this.workElement.nativeElement;
    let aboutEl = this.aboutElement.nativeElement;
    let experienceEl = this.experienceElement.nativeElement;

    let scrollY = window.scrollY + 130;

    if (scrollY < homeEl.clientHeight) this.currentSection = 'home';
    else if (scrollY < homeEl.clientHeight + workEl.clientHeight)
      this.currentSection = 'work';
    else if (
      scrollY <
      homeEl.clientHeight + workEl.clientHeight + aboutEl.clientHeight
    )
      this.currentSection = 'about';
    else if (
      scrollY <
      homeEl.clientHeight +
        workEl.clientHeight +
        aboutEl.clientHeight +
        experienceEl.clientHeight/2
    )
      this.currentSection = 'experience';
    else this.currentSection = 'contact';
  }

  constructor() {}

  ngOnInit(): void {}

  onClickNavItem(event: string) {
    this.currentSection = event;
    let htmlElement: HTMLElement;

    switch (this.currentSection) {
      case 'home':
        htmlElement = this.homeElement.nativeElement;
        break;
      case 'about':
        htmlElement = this.aboutElement.nativeElement;
        break;
      case 'work':
        htmlElement = this.workElement.nativeElement;
        break;
      case 'experience':
        htmlElement = this.experienceElement.nativeElement;
        break;
      case 'contact':
        htmlElement = this.contactElement.nativeElement;
        break;
      default:
        htmlElement = this.homeElement.nativeElement;
        break;
    }

    const yOffset = -70;
    const y =
      htmlElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}
