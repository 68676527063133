import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-home-hero',
  templateUrl: './home-hero.component.html',
  styleUrls: ['./home-hero.component.scss'],
})
export class HomeHeroComponent implements OnInit {
  @Output() navClick: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onNavItemClick(event: string) {
    this.navClick.emit(event);
  }
}
