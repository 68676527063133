import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LinkService } from 'src/app/services/link.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Output() navClick: EventEmitter<string> = new EventEmitter();

  constructor(private linkService: LinkService) {}

  ngOnInit(): void {}

  onFooterNavClick(event: string) {
    this.navClick.emit(event);
  }

  onClickLink(linkID: string) {
    let url = 'https://';
    switch (linkID) {
      case 'linkedin':
        url += 'linkedin.com/in/duriali';
        break;
      case 'github':
        url += 'github.com/duriali';
        break;
      case 'dribbble':
        url += 'dribbble.com/duriali';
        break;
        case 'behance':
        url += 'behance.net/duriali';
        break;
      case 'mail':
        url += 'mailto:abdulr.ali@outlook.com';
        break;
      default:
        break;
    }

    this.linkService.openLinkInNewTab(url);
  }
}
