import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-project-page',
  templateUrl: './project-page.component.html',
  styleUrls: ['./project-page.component.scss'],
})
export class ProjectPageComponent implements OnInit {
  currentSection: string = 'home';

  @ViewChild('home') homeElement: ElementRef = new ElementRef(null);
  @ViewChild('work') workElement: ElementRef = new ElementRef(null);
  @ViewChild('experience') experienceElement: ElementRef = new ElementRef(null);
  @ViewChild('about') aboutElement: ElementRef = new ElementRef(null);
  @ViewChild('contact') contactElement: ElementRef = new ElementRef(null);

  constructor() {}

  ngOnInit(): void {}

  onClickNavItem(event: string) {
    this.currentSection = event;
    let htmlElement: HTMLElement;

    switch (this.currentSection) {
      case 'home':
        htmlElement = this.homeElement.nativeElement;
        break;
      case 'about':
        htmlElement = this.aboutElement.nativeElement;
        break;
      case 'work':
        htmlElement = this.workElement.nativeElement;
        break;
      case 'experience':
        htmlElement = this.experienceElement.nativeElement;
        break;
      case 'contact':
        htmlElement = this.contactElement.nativeElement;
        break;
      default:
        htmlElement = this.homeElement.nativeElement;
        break;
    }

    const yOffset = -70;
    const y =
      htmlElement.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}
