import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  constructor() {}

  openLinkInNewTab(link: string) {
    window.open(link);
  }
}
