import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './components/shared/nav-bar/nav-bar.component';
import { HomeHeroComponent } from './components/home-page/home-hero/home-hero.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { ProjectsSectionComponent } from './components/home-page/projects-section/projects-section.component';
import { ProjectItemComponent } from './components/home-page/projects-section/project-item/project-item.component';
import { AboutSectionComponent } from './components/home-page/about-section/about-section.component';
import { ExperienceSectionComponent } from './components/home-page/experience-section/experience-section.component';
import { ExperienceNodeComponent } from './components/home-page/experience-section/experience-node/experience-node.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { ProjectPageComponent } from './components/project-page/project-page.component';
import { ProjectPageHeroComponent } from './components/project-page/project-page-hero/project-page-hero.component';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomeHeroComponent,
    HomePageComponent,
    ProjectsSectionComponent,
    ProjectItemComponent,
    AboutSectionComponent,
    ExperienceSectionComponent,
    ExperienceNodeComponent,
    FooterComponent,
    ProjectPageComponent,
    ProjectPageHeroComponent,
  ],
  imports: [BrowserModule, AppRoutingModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
