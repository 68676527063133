<app-nav-bar (navClick)="onClickNavItem($event)"></app-nav-bar>

<div>
  <app-project-page-hero></app-project-page-hero>
</div>

<!-- <div class="project-splash">
  <img
    class="img-fluid"
    src="../../../assets/images/large-scale/betterLearn.png"
    alt=""
  />
</div> -->

<div class="container py-5">
  <div class="ratio ratio-16x9 rounded-2">
    <iframe
      class="rounded-4"
      src="https://www.youtube.com/embed/WhYtMGG5Mrg?rel=0&amp;autoplay=0&mute=0"
      title="BetterLearn - The future of learning (Promo)"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-12 text-left">
      <h5 class="text-black header-title">ABOUT</h5>
      <h2 class="header text-black">BetterLearn - by Team Nybble.</h2>

      <br />
      <h5 class="text-grey">
        BetterLearn is an all-inclusive learning management system that caters
        to parents, teachers, school admins and students to manage their
        schooling related tasks and deadlines.
      </h5>
      <br />
      <h5 class="text-grey">
        The BetterLearn web system provides for portals for each of the 4
        different user types while also providing additional portals on the
        mobile application for Teachers, Parents and Students so that they can
        continue tracking, managing and being productive while on the go.
      </h5>
    </div>
  </div>
</div>

<div class="" style="object-fit: cover;" style="margin-top: 100px">
  <img src="../../../assets/images/large-scale/betterLearn.png" class="img-fluid" alt="" srcset="">
</div>

<div class="container" style="margin-top: 100px">
  <div class="row">
    <div class="col-12 col-lg-6 text-left">
      <h5 class="text-black header-title">GOAL</h5>
      <h2 class="header text-black">What was the goal of BetterLearn?</h2>

      <br />
      <h5 class="text-grey">
        BetterLearn was a final year university project with a team of 4
        developers (including me). It was a 9-month long project attempting to
        modernize and digitize learning in a post-covid world where in-person
        learning was severely restricted by government covid-protocols.
      </h5>
      <br />
      <h5 class="text-grey">
        The solution was an all-inclusive digital platform allowing teachers and
        students to continue learning & teaching online.
      </h5>
      <br />
      <h5 class="text-grey">
        The main goals of BetterLearn were:
        <ol>
          <li>Facilitate online classes and course work.</li>
          <li>
            Facilitate online examinations/assessments while also allowing
            students to monitor and keep track of their results.
          </li>
          <li>
            Allow teachers & parents to communicate with eachother in a secure
            and more private way.
          </li>
        </ol>
      </h5>
      <br />
      <h5 class="text-grey">
        Lastly, additional features such as a scheduler, task manager,
        announcements portal as well as a mobile application were added to the
        scope to make the system as wholistic as possible.
      </h5>
      <br />
      <h5 class="text-grey">Watch the full demo of BetterLearn below 👇</h5>
    </div>

    <div class="col-12 col-lg-6 text-left">
      <h5 class="text-black header-title">OUTCOME</h5>
      <h2 class="header text-black">What were the results?</h2>

      <br />
      <h5 class="text-grey">
        BetterLearn was completed by Sempter, 2021 and presented at the Academy of Computer Science & Software Engineering’s Project Day on October 20th.
      </h5>
      <br />
      <h5 class="text-grey">
        TeamNybble won third place for their solution: BetterLearn.
      </h5>
    </div>
  </div>
</div>

<div class="container py-5">
  <div class="ratio ratio-16x9 rounded-2">
    <iframe
      class="rounded-4"
      src="https://www.youtube.com/embed/6MCF-mZ9a5o"
      title="BetterLearn - The future of learning (Product Demo)"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</div>

<div class="container">
  <div class="row g-4">
    <div class="col-12 col-lg-6">
      <img
        src="../../../assets/images/betterlearn.png"
        class="img-fluid"
        alt=""
      />
    </div>
    <div class="col-12 col-lg-6">
      <img
        src="../../../assets/images/betterlearn.png"
        class="img-fluid"
        alt=""
      />
    </div>
    <div class="col-12 col-lg-6">
      <img
        src="../../../assets/images/betterlearn.png"
        class="img-fluid"
        alt=""
      />
    </div>
    <div class="col-12 col-lg-6">
      <img
        src="../../../assets/images/betterlearn.png"
        class="img-fluid"
        alt=""
      />
    </div>
  </div>
</div>

<div #contact style="margin-top: 100px">
  <app-footer (navClick)="onClickNavItem($event)"></app-footer>
</div>
