import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects-section',
  templateUrl: './projects-section.component.html',
  styleUrls: ['./projects-section.component.scss'],
})
export class ProjectsSectionComponent implements OnInit {
  projectItems: {
    title: string;
    description: string;
    image: string;
    url: string;
    year: number
  }[] = [
    {
      title: 'BetterLearn',
      description: 'Complete Learning Management System',
      image: 'betterlearn.png',
      url: 'https://www.behance.net/gallery/151996287/BetterLearn-A-Complete-LMS',
      year: 2021,
    },
    {
      title: 'Freedom of Realty',
      description: 'Commercial Website',
      image: 'fomrealty.png',
      url: 'https://fomrealty.com',
      year: 2021,
    },
    {
      title: 'Grocery Hour',
      description: 'Online Grocery Store Web App',
      image: 'groceryHour.png',
      url: 'https://dribbble.com/shots/15091780-GroceryHour',
      year: 2020,
    },
    {
      title: 'Handy Hire',
      description: 'Service Jobs Hire System',
      image: 'handyhire.png',
      url: 'https://dribbble.com/shots/19283716-HandyHire-Job-Ticketing-System',
      year: 2019,
    },
    {
      title: 'Taxomics LLC',
      description: 'Commercial Website',
      image: 'taxomics.png',
      url: 'https://taxomics.com',
      year: 2020,
    },
    {
      title: 'Todo App',
      description: 'Flutter Mobile Todo App',
      image: 'dbTodo.png',
      url: 'https://dribbble.com/shots/15091894-Task-Manager-Mobile-App',
      year: 2021,
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
