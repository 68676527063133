<div class="experience-node px-5 px-md-0 py-2 py-md-5" [class.is-main]="isMain">
  <div class="row bigScreen" [class.flex-row-reverse]="reverse">
    <div class="col-5" [class.text-end]="!reverse" [class.ps-5]="!reverse">
      <p class="">
        {{ experienceInfo.time }}
      </p>
    </div>
    <div class="col-2">
      <div class="dot"></div>
    </div>
    <div class="col-5" [class.text-end]="reverse" [class.pe-5]="!reverse">
      <h4 class="fw-bold m-0 p-0">{{ experienceInfo.company }}</h4>
      <p class="m-0 p-0">{{ experienceInfo.position }}</p>
      <p *ngIf="experienceInfo.description" class="m-0 p-0 mt-3">
        {{ experienceInfo.description }}
      </p>
    </div>
  </div>
  <div class="row smallScreen">
    <div class="dot"></div>
    <div class="col-12">
      <h4 class="fw-bold m-0 p-0">{{ experienceInfo.company }}</h4>
      <p class="m-0 p-0" style="font-weight: 500">{{ experienceInfo.position }}</p>
      <p class="">
        {{ experienceInfo.time }}
      </p>
      <p *ngIf="experienceInfo.description" class="m-0 p-0">
        {{ experienceInfo.description }}
      </p>
    </div>
  </div>
</div>