<div class="hero-section">
  <div class="container">
    <div class="row text-start align-items-center">
      <div class="col-12 col-lg-6">
        <div class="horizontal-bar"></div>
        <h1>Better Learn</h1>
        <h2>Complete Management System</h2>
        <br>
        <p>
          An all-inclusive learning management system catering to parents,
          teachers, school admins and students to manage their schooling related
        </p>
      </div>
      <div class="col-12 col-lg-6 text-center text-lg-end">
        <button class="btn btn-outline-dark rounded-circle">
            <i class="fa-solid fa-arrow-down"></i>
        </button>
      </div>
    </div>
  </div>
</div>
