<app-nav-bar [currentSection]="currentSection" (navClick)="onClickNavItem($event)"></app-nav-bar>

<div #home>
  <app-home-hero></app-home-hero>
</div>

<div #work>
  <app-projects-section></app-projects-section>
</div>

<div #about style="margin-top: 100px; margin-bottom: 100px">
  <app-about-section></app-about-section>
</div>

<div #experience style="margin-top: 100px; margin-bottom: 100px">
  <app-experience-section></app-experience-section>
</div>

<div #contact style="margin-top: 100px">
  <app-footer (navClick)="onClickNavItem($event)"></app-footer>
</div>
