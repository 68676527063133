import { Component, OnInit } from '@angular/core';
import { LinkService } from 'src/app/services/link.service';

@Component({
  selector: 'app-about-section',
  templateUrl: './about-section.component.html',
  styleUrls: ['./about-section.component.scss'],
})
export class AboutSectionComponent implements OnInit {
  ngOnInit(): void {}

  constructor(private linkService: LinkService) {}
  onClickLink(linkID: string) {
    let url = 'https://';
    switch (linkID) {
      case 'linkedin':
        url += 'linkedin.com/in/duriali';
        break;
      case 'github':
        url += 'github.com/duriali';
        break;
      case 'dribbble':
        url += 'dribbble.com/duriali';
        break;
      case 'behance':
        url += 'behance.net/duriali';
        break;
      case 'mail':
        url += 'mailto:abdulr.ali@outlook.com';
        break;
      default:
        break;
    }

    this.linkService.openLinkInNewTab(url);
  }
}
