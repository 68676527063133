<div class="card" (click)="onClick()">
  <div class="card-body p-4">
    <img
      src="../../../../../assets/images/{{ projectItemInfo.image }}"
      class="img-fluid"
      alt=""
    />
    <div class="mt-3 d-flex justify-content-between align-items-center">
      <div class="project-item-description">
        <h4 class="fw-bold m-0 p-0 pb-1">{{ projectItemInfo.title }}</h4>
        <p class="text-secondary p-0 m-0">
          {{ projectItemInfo.description }}  •  {{ projectItemInfo.year }}
        </p>
      </div>
      <div>
        <button
          (click)="onClick()"
          class="btn btn-outline-secondary rounded-circle"
        >
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>
