import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-experience-section',
  templateUrl: './experience-section.component.html',
  styleUrls: ['./experience-section.component.scss'],
})
export class ExperienceSectionComponent implements OnInit {
  experienceInfos: {
    company: string;
    position: string;
    time: string;
    description?: string;
  }[] = [
    {
      company: 'Dashpay',
      position: 'Intermediate Software Engineer (Via Dariel)',
      time: 'Nov 2023 - present',
      description:
        'Working as a Frontend Lead in a Dariel team of developers attempting to rewrite an existing Point-of-Sale Terminal Management System using the Vue framework together with Micro-Frontend technology: Single SPA.',
    },
    {
      company: 'Sibanye Stillwater',
      position: 'Intermediate Software Engineer (Via Dariel)',
      time: 'April 2023 - Nov 2023',
    },
    {
      company: 'First National Bank (FNB)',
      position: 'Junior Software Engineer (Via Dariel)',
      time: 'November 2022 - March 2023',
    },
    {
      company: 'Life Healthcare',
      position: 'Graduate Software Engineer (Via Dariel)',
      time: 'March 2022 - October 2022',
    },
    {
      company: 'Dariel',
      position: 'Software Engineer',
      time: 'Feb 2022 - today',
    },
    {
      company: 'Nedbank',
      position: 'Research Intern',
      time: 'December 2021',
    },
    {
      company: 'Freedom of Movement Realty',
      position: 'Freelance Web Developer',
      time: 'December 2021',
    },
    {
      company: 'Nedbank',
      position: 'Research Intern',
      time: 'June 2021',
    },
    {
      company: 'Nedbank',
      position: 'Design Intern',
      time: 'December 2020',
    },

    {
      company: 'Taxomics LLC',
      position: 'Freelance Web Developer',
      time: 'May 2020 - Aug 2020',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
