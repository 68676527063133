<div class="container">
  <h5 class="text-secondary header-title">EXPERIENCE</h5>
  <h2 class="header">WHERE HAVE I BEEN?</h2>

  <div class="road-map pt-4 px-4 px-lg-0" >
    <app-experience-node
      *ngFor="let experience of experienceInfos; let i = index"
      [isMain]="i == 0"
      [reverse]="i % 2 != 0"
      [experienceInfo]="experience"
    ></app-experience-node>
  </div>
</div>
