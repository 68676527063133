import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-page-hero',
  templateUrl: './project-page-hero.component.html',
  styleUrls: ['./project-page-hero.component.scss']
})
export class ProjectPageHeroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
