<div class="container">
  <h5 class="text-secondary header-title">PROJECTS</h5>
  <h2 class="header">SOME OF THE PROJECTS I'VE WORKED ON</h2>

  <div class="row g-4 pt-4">
    <app-project-item
      *ngFor="let item of projectItems"
      [projectItemInfo]="item"
      class="col-12 col-md-6"
    ></app-project-item>
  </div>
</div>
